//sp menu
$(function(){
  $('.menu_trigger, .menu_link').click(function() {
    $('.menu_trigger').toggleClass('is_active');
    $('.cls').toggleClass('is_active');
    $('body,html').toggleClass('is_active');
  });
});

$(window).scroll(function (){
  $(".animation").each(function(){
    var offset = $(this).offset().top;
    var objectHeight = $(this).height();
    var scroll = $(window).scrollTop();
    var wHeight = $(window).height();

    if (scroll > offset - wHeight + objectHeight/4 && offset + objectHeight > scroll){
      $(this).addClass("is_active");
    } else{
      $(this).removeClass("is_active");
    }
  });
});

$(function () {
  var headerHight = 0;
  $('a[href^=#]').click(function(){
    var href= $(this).attr("href");
    var target = $(href == "#" || href == "" ? 'html' : href);
    var position = target.offset().top-headerHight;
    $("html, body").animate({scrollTop:position}, 400, "swing");
    return false;
  });
});

$(function(){
  $('.achievement_item').click(function() {
    $(this).children('').toggleClass('is_modal');
    $('body').toggleClass('is_modal');
  });
  $('.achievement_item::after, .archive_modal_close').click(function() {
    $('.achievement_item, body').removeClass('is_modal');
  });
});
